import Http from "@/libraries/Http";

export default {
    apiVersion: "v1",
    RESTroute: "invoices",
    RESTrouteLines: "invoice-lines",

    /**
     * PROJECTS & PROJECT INVOICING
     */

    async GET_contractBalances(year, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/contract-balances/${year}`,
            {
                params: params
            }
        );
    },

    async GET_projectsOverviewResponsible(params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/projects-overview-responsible`,
            {
                params: params
            }
        );
    },
    async GET_projectsOverview(params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/projects-overview`,
            {
                params: params
            }
        );
    },

    async PUT_updateProjectLayoutTerms(invoice_id, data = {}) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/update-project-layout-terms/${invoice_id}`,
            data
        );
    },

    async POST_revokeProjectInvoice(invoice_id, data = {}) {
        return Http.post(
            `/${this.apiVersion}/${this.RESTroute}/revoke-project-invoice/${invoice_id}`,
            data
        );
    },

    async POST_invoiceProjects(data, params = {}) {
        return Http.post(
            `/${this.apiVersion}/${this.RESTroute}/invoice-projects`,
            data,
            { params: params }
        );
    },

    async GET_projectInvoicing(params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/project-invoicing`,
            {
                params: params
            }
        );
    },

    /**
     * Get project balances -> display above the invoice
     */
    async GET_projectBalances(project_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/project-balances/${project_id}`,
            {
                params: params
            }
        );
    },

    /**
     * Create a line or multiple lines from one or more travel entries.
     * Complex input format.
     * @param {} data
     * @returns
     */
    async POST_fromTravels(data = {}) {
        return Http.post(
            `/${this.apiVersion}/${this.RESTrouteLines}/from-travels`,
            data
        );
    },

    /**
     * Create a line or multiple lines from one or more activities.
     * Complex input format.
     * @param {} data
     * @returns
     */
    async POST_fromActivities(data = {}) {
        return Http.post(
            `/${this.apiVersion}/${this.RESTrouteLines}/from-activities`,
            data
        );
    },

    /**
     * Set invoice status back to "draft"
     * @param {*} invoice_id
     * @returns
     */
    async PUT_setAsDraft(invoice_id) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/set-as-draft/${invoice_id}`
        );
    },
    /**
     * Set invoice status as "ready to invoice"
     * @param {*} invoice_id
     * @returns
     */
    async PUT_setAsReady(invoice_id) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/set-as-ready/${invoice_id}`
        );
    },

    /**
     * Delete invoice line
     * @param {*} invoice_line_id
     * @returns
     */
    async DELETE_invoiceLine(invoice_line_id) {
        return Http.delete(
            `/${this.apiVersion}/${this.RESTrouteLines}/${invoice_line_id}`
        );
    },
    /**
     * Edit invoice line, must contain invoice_line_id
     * @param {} data
     * @returns
     */
    async PUT_editInvoiceLine(data = {}) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTrouteLines}/${data.id}`,
            data
        );
    },
    /**
     * Create invoice line
     * @param {} data
     * @returns
     */
    async POST_createInvoiceLine(data = {}) {
        return Http.post(`/${this.apiVersion}/${this.RESTrouteLines}`, data);
    },

    /**
     * Update invoice head
     * @param {} data
     */
    async PUT_updateInvoiceHead(data = {}) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/update-invoice-head`,
            data
        );
    },

    /**
     * Create a draft invoice for a project (regardless type)
     * @param {*} project_id
     * @param {*} data
     * @returns
     */
    async POST_projectCreateDraft(project_id, data = {}) {
        return Http.post(
            `/${this.apiVersion}/${this.RESTroute}/project-create-draft/${project_id}`,
            data
        );
    },

    async GET_projectInvoices(project_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/project-invoices/${project_id}`,
            {
                params: params
            }
        );
    },

    /**
     * Temporary methods for 2020 turnover
     * @param {} data
     */
    async PUT_ManualInvoice(data, contract_id) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/manual-invoice/${contract_id}`,
            data
        );
    },

    async GET_ManualInvoice(contract_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/manual-invoice/${contract_id}`,
            {
                params: params
            }
        );
    },

    /**
     * Invoice charges
     * @param {*} data
     */
    async invoiceCharges(data) {
        return Http.post(
            `/${this.apiVersion}/${this.RESTroute}/invoice-charges`,
            data
        );
    },

    /**
     * Invoice subscriptions
     * @param {} data
     */
    async invoiceSubscriptions(data) {
        return Http.post(
            `/${this.apiVersion}/${this.RESTroute}/invoice-subscriptions`,
            data
        );
    },

    /**
     * Invoice contracts
     * @param {*} data
     */
    async invoiceContracts(data) {
        return Http.post(
            `/${this.apiVersion}/${this.RESTroute}/invoice-contracts`,
            data
        );
    },

    /**
     * Returns next invoicing, adjustment and cancellation dates + status
     * on whether the contract is terminated.
     * @param int contract_id
     */
    async contractNextDates(contract_id) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/contract-next-dates/${contract_id}`
        );
    },

    /**
     * Upcoming charge invoices
     * @param {} params
     */
    async upcomingChargeInvoices(params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/charge-invoicing`,
            {
                params: params
            }
        );
    },

    /**
     * Returns all subscription upcoming invoices
     * @param  params
     */
    async upcomingSubscriptionInvoices(params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/subscription-invoicing`,
            {
                params: params
            }
        );
    },

    /**
     * Returns all upcoming invoices
     * @param  params
     */
    async upcomingInvoices(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/invoicing`, {
            params: params
        });
    },

    /**
     * Preview invoicing for a given (pre-existing) contract
     * @param {} contract_id
     */
    async invoicingPreviewContract(contract_id) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/invoicingPreview`,
            {
                params: {
                    contract_id: contract_id
                }
            }
        );
    },

    /**
     * Preview invoicing for inexisting contract (requires data array with params)
     * @param {*} data
     */
    async invoicingPreviewData(data) {
        return Http.post(
            `/${this.apiVersion}/${this.RESTroute}/invoicingPreviewFromData`,
            data
        );
    },

    /**
     * Get filters
     * GET
     */
    async invoiceFilters(params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/invoice-filters`,
            {
                params: params
            }
        );
    },

    /**
     * Get all resources
     * GET /resource
     */
    async GET(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}`, {
            params: params
        });
    },

    /**
     * Get single resource
     * GET /resource/<id>
     */
    async GETS(id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/${id}`, {
            params: params
        });
    },

    /**
     * Create resource
     * POST /resource
     * Payload: {}
     */
    async POST(data) {
        return Http.post(`/${this.apiVersion}/${this.RESTroute}`, data);
    },

    /**
     * Put resource
     * PUT /resource/<id>
     * Payload: {}
     */
    async PUT(data) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/${data.id}`,
            data
        );
    },

    /**
     * Delete resource
     * DELETE /resource/<id>
     */
    async DELETE(id) {
        return Http.delete(`/${this.apiVersion}/${this.RESTroute}/${id}`);
    }
};
